<template>
  <div class="breadcrumb-row">
    <div class="container">
      <ul class="list-inline">
        <li><router-link to="/" href="#">Home</router-link></li>
        <li :key="item.label" v-for="item of this.data">
          <router-link v-if="item.url" :to="item.url">{{
            item.label
          }}</router-link>
          <span v-if="!item.url"> {{ item.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    data: Array,
  },
});
</script>

<style scoped>
.list-inline {
  overflow: auto;
  font-family: Oswald, sans-serif;
}
</style>
